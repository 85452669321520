import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth
        };
        this.updateWindowSize = this.updateWindowSize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowSize.bind(this))
    }

    updateWindowSize() {
        const windowWidth = window.innerWidth;
        this.setState({ windowWidth })
    }

    render() {
        const { page, t } = this.props;
        const localLanguage = window.localStorage.getItem('kailonaLanguage');
        const kailonaLanguage = localLanguage ? (localLanguage.toLowerCase().includes('en') ? 'en': 'de') : 'en';
        const paragraphs = page.paragraphs[kailonaLanguage];
        const paraLength = paragraphs.length;

        if (this.props.inMain) {
            return (
                <div className={`content-wrapper ${page.name}-bg`}>
                    <div className='text-content mobile-content'>
                        <h1>{t(page.title)}</h1>
                    </div>
                    <div className="bg-name">{page.backgroundName}</div>
                </div>
            );
        }

        if (this.state.windowWidth <= 800 && !this.props.inMain) {
            return (
                <div className={`content-wrapper ${page.name}-bg`}>
                    <div className='text-content mobile-content' style={{ width: '100%'}}>
                        <h4 style={{ marginBottom: '10px', marginTop: '0'}}>{t(page.name)}</h4>
                        <h1>{t(page.title)}</h1>
                        <Carousel style={{ width: '100%'}}>
                            {paragraphs.filter(para =>t(para).length).map(para => (
                                <div>
                                    <p className="body-lead" dangerouslySetInnerHTML={{ __html: t(para) }} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="bg-name">{page.backgroundName}</div>
                </div>
            );
        }

        return (
            <div className={`content-wrapper ${page.name}-bg`}>
                <div className={paraLength > 3 ? 'text-content lg' : 'text-content'}>
                    <h4 style={{ marginBottom: '10px', marginTop: '0'}}>{t(page.name)}</h4>
                    <h1>{t(page.title)}</h1>
                    {
                        paragraphs.length <= 3 ?
                            paragraphs.map(para => (
                                <p className="body-lead" dangerouslySetInnerHTML={{ __html: t(para) }} />
                            ))
                            :
                            <div style={{display: 'flex', width: '100%'}}>
                                <div style={{width: '48%', marginRight: '2%'}}>
                                    {
                                        paragraphs.map((para, index) => {
                                            if (index < paraLength / 2) {
                                                return (
                                                    <p className="body-lead" dangerouslySetInnerHTML={{ __html: t(para) }} />
                                                );
                                            }
                                        })
                                    }
                                </div>
                                <div style={{ width: '48%'}}>
                                    {
                                        paragraphs.map((para, index) => {
                                            if (index >= paraLength / 2) {
                                                return (
                                                    <p className="body-lead" dangerouslySetInnerHTML={{ __html: t(para) }} />
                                                );
                                            }
                                        })
                                    }
                                </div>
                            </div>
                    }
                </div>
                <div className="bg-name">{page.backgroundName}</div>
            </div>
        );

    }
}

export default withTranslation()(Page);
