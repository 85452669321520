import React from 'react'
import { Link } from 'react-router-dom'
import './DropdownNav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import pages from '../page-components/Pages/index';
import LanguageSelect from '../header-components/LanguageSelect';
import { withTranslation } from 'react-i18next';

class DropdownNav extends React.Component {

    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this)
    }

    state = {
        showMenu: false
    }

    toggleMenu() {
        var showMenu = this.state.showMenu == true ? false : true;
        this.setState({ showMenu })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="toggle-nav">
                    <p onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon={faBars} />
                    </p>
                </div>
                { this.state.showMenu === false ?
                    null
                    :
                    <div className="dropdown-nav">
                        <div className="dropdown-nav-content">
                            <div className="nav-item">
                                <a href="https://docs.kailona.org/" target="_blank" className="ehr-action">
                                    {t('GetStarted')}
                                </a>
                            </div>

                            {pages.map(item => (
                                <div key={item.name} isselected={this.props.state.router.link === item.link} onClick={this.toggleMenu} className="nav-item">
                                    <Link to={item.link}>{t(item.name)}</Link>
                                </div>

                            ))}
                            <div className="nav-item">
                                <LanguageSelect/>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default withTranslation()(DropdownNav);
