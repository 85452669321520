import React from 'react'
import Slider from "react-slick";
import sliderBackgrounds from './SliderBackgrounds';
import './Homepage.css'

class Homepage extends React.Component {

    constructor(props) {
        super(props);
        window.self = this;
        this.styles = [];
    }

    settings = {
        dots: true,
        fade: true,
        autoplay: true,
        prevArrow: false,
        nextArrow: false,
        arrows: false,
        infinite: false,
        speed: 3000,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
    };

    render() {
        return (
            <>
            <style>
                {this.styles}
            </style>
            <div className="homepage">
                <div className="slide-show">
                    <Slider {...this.settings}>
                        {sliderBackgrounds.map(sb => sb.background)}
                    </Slider>
                </div>
            </div>
            </>
        )
    }
}

export default Homepage;
