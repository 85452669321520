import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({

    fallbackLng: "en",
    whitelist: ['en',
        'en-US',
        'de',
        'de-DE',
        'tr',
        'tr-TR',
    ],
    debug: true,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true
    },
    resources: {
        en: {
            translations: {
                Dignity: 'Dignity',
                DignityTitle: 'The dignity of all humans is untouchable.',
                DignityPara1: 'Since no one chooses to get sick, no one should be discriminated for physical or mental health issues.',
                DignityPara2: 'Yet, <a href="https://media.ccc.de/v/rc3-11342-tut_mal_kurz_weh_neues_aus_der_gesundheits-it" target="_blank">personal health data</a> is ' +
                '<a href="https://media.ccc.de/v/35c3-9992-all_your_gesundheitsakten_are_belong_to_us#t=24" target="_blank">exploited</a> and used to discriminate against people, devastating their\n' +
                'ability to find jobs, insurance coverage and economic stability.',
                DignityPara3: 'Kailona™ provides open source health information technology to protect people from data exploitation,\n' +
                'give them control over their health data, and improve the health of individuals and societies.',
                Trust: 'Trust',
                TrustTitle: 'The world can verify: we keep your health data secure.',
                TrustPara1: 'Right now, governments, device makers, and software companies are\n' +
                'handing out your health data for <a href="http://www.myhealthmydata.eu/why-mhmd/" target="_blank">corporations to exploit.</a>',
                TrustPara2: 'Developers use software languages to instruct computers and devices on what to do.\n' +
                'The language is written as code. This code tells a system how to store data and how to move data around,\n' +
                'including your health data. Most corporations hide their code to hide how they exploit your data.\n' +
                'Their code books are sealed from the public and cannot be trusted.',
                TrustPara3: ' Kailona™ provides open source code to the public. Our code is transparent so that you can trust us\n' +
                'to keep your data secure.',
                'Self-Determination': 'Self-Determination',
                'Self-DeterminationTitle': 'Your health data is yours. You decide what to share and who to share it with.',
                'Self-DeterminationPara1': 'For almost everyone, it’s nearly impossible to know who to trust with your health data, how to keep\n' +
                'from being exploited, and how to practice self-determination in the digital age.',
                'Self-DeterminationPara2': 'Kailona™ empowers you to securely aggregate and distribute your health information.',
                'Self-DeterminationPara3': 'Imagine securely sharing ultrasounds of the baby with your family.\n' +
                'Or being able to share blood test results to get a second opinion from a holistic medical provider.\n' +
                'Or more easily sharing guardianship and health updates of a beloved parent as they age.',
                Healing: 'Healing',
                HealingTitle: 'Sharing an anonymized version of your data allows researchers to make faster progress with effective treatments.',
                HealingPara1: 'Contemporary medicine is good at diagnosing illness, but not as good at healing people.\n' +
                'Right now, the medical industry sequences genomes, gathers billions of data points, and\n' +
                'generates millions of medical images, but it doesn’t have a way to fully utilize it.\n' +
                'Exponential amounts of data has not led to an exponential improvement in effective\n' +
                'methods of treatment and healing. Behavioral and nutritional data are the missing pieces.',
                HealingPara2: 'We know that a lot of healing has to do with your behavior and nutrition.\n' +
                'Kailona™ allows you to track behavioral and nutritional data and relate it with medical data;\n' +
                'this is an opportunity for you to discover paths towards health and healing.',
                HealingPara3: 'Kailona™ allows you to systematically manage activity, sleep, weight, nutrition, vital signs,\n' +
                'lab results, medical images, medicines, and genomic data. Visualization tools allow you to\n' +
                'understand the relationships between the data, your behavior and your health.',
                HealingPara4: 'Kailona™ also enables you to help others. By securely sharing an anonymized version of your\n' +
                'data with the research community, you accelerate innovation in diagnosis and treatment.\n' +
                'You are not alone in your diagnoses, but rather a part of the effort to heal yourself and others.',
                About: 'About',
                AboutTitle: 'About Kailona',
                AboutPara1: 'Kailona ("kailo-" Proto-Indo-European for "whole, uninjured, of good omen") provides a global\n' +
                '<a href="https://github.com/Kailona"  target="_blank">open source platform for developers</a> that allows individuals to make healthier choices and the research and developer community to accelerate health innovation.',
                AboutPara2: 'We are an international team of designers, sociologists, anthropologists, and engineers passionate\n' +
                'about the dignity, self-determination, trust and healing of people all over the globe.',
                AboutPara3: 'The Kailona team is proud to be sponsored by the Prototype Fund, a project of the Open Knowledge\n' +
                'Foundation Germany, funded by the Federal Ministry of Education and Research (BMBF) Förderkennzeichen 01|S20S38, ' +
                '<a href="http://www.medken.com" target="_blank">MEDKEN</a>, <a href="http://www.acanio.com" target="_blank">ACANIO</a> and ' +
                '<a href="http://www.toca.com" target="_blank">TOCA</a>.' +
                '<div class="sponsors">' +
                '<a href="https://www.bmbf.de/" target="_blank"><img src="/sponsors/BMBF-logo-white.svg"/></a>' +
                '<a href="https://okfn.de/" target="_blank"><img src="/sponsors/OKFD-logo-white.svg"/></a>' +
                '<a href="https://prototypefund.de" target="_blank" class="sponsor-prototype-fund"><img src="/sponsors/prototype-fund-logo-white.svg"/></a>' +
                '<a href="https://medken.com" target="_blank" class="sponsor-medken"><img src="/sponsors/medken-white.svg"/></a>' +
                '<a href="https://acanio.com" target="_blank" class="sponsor-acanio"><img src="/sponsors/acanio-logo-white.svg"/></a>' +
                '<a href="https://toca.com/" target="_blank" class="sponsor-toca"><img src="/sponsors/toca-logo-white.svg"/></a>' +
                '</div>',
                AboutPara4: '<div class="legal-notice"><h3>Legal Notice</h3>' +
                '<div style="margin-bottom: 15px;">Kailona GbR (Owners Armin Möhrle, Aysel Afsar, Evren Ozkan, Sanya Glisic)</div>' +
                '<div>Schulstr. 12</div>' +
                '<div>78462 Konstanz</div>' +
                '<div>legal@kailona.org</div>' +
                '<div style="margin-top: 15px;">All product names and trademarks are the property of their respective owners, which might not be ' +
                'associated or affiliated with Kailona GbR. </div>' +
                '<div style="margin-top: 15px;">Last Edited on 04/23/2021</div>' +
                '</div>',
                Press: 'Press',
                PressTitle: 'Press',
                PressPara1: '<div class="press-release">' +
                '<div class="press-release-item">' +
                '<a href="https://www.heise.de/news/Kailona-will-Cloud-Alternative-zur-elektronischen-Patientenakte-anbieten-6024875.html" ' +
                'target="_blank" class="press-release-item-title">Kailona will Cloud-Alternative zur elektronischen Patientenakte anbieten</a>' +
                '<div class="press-release-item-source">heise</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://www.golem.de/news/kailona-regierung-unterstuetzt-gesundheitsdaten-in-nextcloud-2104-155904.html" ' +
                'target="_blank" class="press-release-item-title">Regierung unterstützt Gesundheitsdaten in Nextcloud</a>' +
                '<div class="press-release-item-source">golem</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://www.linux-community.de/nachrichten/nextcloud-mit-kailona-app-bietet-open-source-cloud-fuer-gesundheitsdaten/" ' +
                'target="_blank" class="press-release-item-title">Nextcloud mit Kailona-App bietet Open-Source-Cloud für Gesundheitsdaten</a>' +
                '<div class="press-release-item-source">LINUX COMMUNITY</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://osb-alliance.de/news/bundesregierung-finanziert-open-source-cloud-fuer-patientendaten" ' +
                'target="_blank" class="press-release-item-title">Bundesregierung finanziert Open-Source-Cloud für Patientendaten</a>' +
                '<div class="press-release-item-source">OSB ALLIANCE</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://www.linux-magazin.de/news/nextcloud-mit-open-source-app-fuer-patientendaten/"  ' +
                'target="_blank" class="press-release-item-title">Nextcloud mit Open-Source-App für Patientendaten</a>' +
                '<div class="press-release-item-source">LINUX MAGAZIN</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://c2wlabnews.nl/nextcloud-met-een-open-source-patientgegevensapplicatie/" ' +
                'target="_blank" class="press-release-item-title">Nextcloud met een open source patiëntgegevensapplicatie</a>' +
                '<div class="press-release-item-source">C2W LabNews</div>' +
                '</div>' +
                '</div>',
                GetStarted: 'Get Started',
                AppStore: 'EHR on Nextcloud App Store'
            }
        },
        de: {
            translations: {
                Dignity: 'Würde',
                DignityTitle: 'Die Würde des Menschen ist unantastbar.',
                DignityPara1: 'Da sich niemand dafür entscheidet krank zu werden, darf auch niemand wegen körperlicher oder mentaler Gesundheitsprobleme diskriminiert werden.',
                DignityPara2: 'Persönliche Gesundheitsdaten werden jedoch benutzt, um Menschen zu diskriminieren und ihre Möglichkeiten Arbeit oder Versicherungsschutz zu finden einzuschränken.',
                DignityPara3: 'Kailona bietet Open-Source-Gesundheitsinformationstechnologie, um Menschen vor Datenmissbrauch\n' +
                'zu schützen, ihnen die Kontrolle über ihre Gesundheitsdaten zu\n' +
                'geben und die Gesundheit von Einzelpersonen und Gesellschaften zu\n' +
                'verbessern.',
                Trust: 'Vertrauen',
                TrustTitle: 'Die Welt kann unseren Code überprüfen: Wir schützen Ihre Gesundheitsdaten.',
                TrustPara1: 'Derzeit verteilen Regierungen, Gerätehersteller und Softwareunternehmen Ihre Gesundheitsdaten, damit Unternehmen sie ausnutzen können.',
                TrustPara2: 'Entwickler\n' +
                'verwenden Softwaresprachen, um Computer und Geräte anzuweisen, wie\n' +
                'Funktionen ausgeführt werden sollen. Die Sprache ist als Code\n' +
                'geschrieben. Dieser Code teilt einem System mit, wie Daten\n' +
                'gespeichert und Daten verschoben werden, einschließlich Ihrer\n' +
                'Gesundheitsdaten. Die meisten Unternehmen verbergen ihren Code, um zu\n' +
                'verbergen, wie sie Ihre Daten ausnutzen. Ihre Codebücher sind für\n' +
                'die Öffentlichkeit versiegelt und können nicht als vertrauenswürdig\n' +
                'eingestuft werden.',
                TrustPara3: 'Kailona stellt der Öffentlichkeit Offenen Quellcode zur Verfügung. Unser Code ist transparent, sodass Sie uns vertrauen können, dass Ihre Daten sicher sind.',
                'Self-Determination': 'Selbstbestimmung',
                'Self-DeterminationTitle': 'Ihre Gesundheitsdaten gehören Ihnen. Sie entscheiden, was Sie teilen und mit wem Sie es teilen möchten.',
                'Self-DeterminationPara1': 'Für fast alle ist es fast unmöglich zu wissen, wem Sie Ihre Gesundheitsdaten anvertrauen können, wie Sie verhindern können, dass sie ausgenutzt werden, und wie Sie im digitalen Zeitalter Selbstbestimmung ausüben können.',
                'Self-DeterminationPara2': 'Mit Kailona können Sie Ihre Gesundheitsinformationen sicher zusammenfassen und gezielt Zugang gewähren.',
                'Self-DeterminationPara3': 'Zum Beispiel können Sie Ihrer Familie gezielten Zugang zum Ultraschall Ihres Babys geben. Oder Sie geben einem weiteren Artzt Zugang zu Ihren Bluttestergebnisse um eine zweite Meinung zu bekommen. Sie können auch die Gesundheitsdaten der Kinder oder Eltern verwalten.',
                Healing: 'Selbstheilung',
                HealingTitle: 'Wenn Sie den Zusammenhang zwischen körperlicher Aktivität, Ernährung und Gesundheitsdaten verstehen ist die Wahrscheinlichkeit viel grösser, dass Sie Ihr Verhalten ändern.',
                HealingPara1: 'Die\n' +
                'industrielle Medizin ist oft gut darin, Krankheiten zu\n' +
                'diagnostizieren, erfüllt jedoch nicht ihr Versprechen, wenn es um\n' +
                'die Heilung von Menschen geht. Derzeit sequenziert die medizinische\n' +
                'Industrie Genome, sammelt Milliarden von Datenpunkten und generiert\n' +
                'unzählige medizinische Bilder. Der Zuwachs an Daten steht allerdings\n' +
                'in keiner Relation zum Fortschritt in der Heilung. Körperliche\n' +
                'Aktivität, Ernährungsdaten, mentale und emotionale Daten sind die\n' +
                'kritischen fehlenden Teile. Durch die Freigabe einer anonymisierten\n' +
                'Version Ihrer Daten können Forscher mit effektiven Behandlungen\n' +
                'schneller Fortschritte erzielen.',
                HealingPara2: '',
                HealingPara3: 'Mit Kailona\n' +
                'können Menschen systematisch Aktivität, Schlaf, Gewicht, Ernährung,\n' +
                'Nahrungsergänzungsmittel, Vitalfunktionen, Laborergebnisse,\n' +
                'medizinische Bilder, pharmazeutische, genomische Daten und Daten über\n' +
                'die mentalen und emotionalen Zustände verwalten.\n' +
                'Visualisierungwerkzeuge ermöglichen es Menschen, die Beziehungen\n' +
                'zwischen den Daten zu verstehen und sie ermutigen, gesündere\n' +
                'Entscheidungen zu treffen. Kailona ist eine Gelegenheit für\n' +
                'Menschen, Wege zur Selbstheilung zu entdecken .',
                HealingPara4: '',
                About: 'Über uns',
                AboutTitle: 'Über Kailona',
                AboutPara1: 'Kailona ("kailo-" Proto-Indo-European für "ganz, unverletzt, von gutem Omen") bietet\n' +
                'eine globale Open-Source-Plattform für Entwickler, die es Einzelpersonen ermöglicht, gesündere Entscheidungen zu\n' +
                'treffen, und der Forschungs- und Entwicklergemeinschaft, um Gesundheitsinnovationen zu beschleunigen.',
                AboutPara2: 'Wir sind ein internationales Team von Designern, Soziologen, Anthropologen und Ingenieuren, die sich leidenschaftlich für die Würde,\n' +
                'Selbstbestimmung, das Vertrauen und die Heilung von Menschen auf der ganzen Welt einsetzen.',
                AboutPara3: 'Das Kailona-Team ist stolz darauf, vom Prototype Fund, einem Projekt der Open Knowledge Foundation Deutschland, dem Bundesministerium für Bildung und Forschung (BMBF), ' +
                '<a href="http://www.medken.com" target="_blank">MEDKEN</a>, <a href="http://www.acanio.com" target="_blank">ACANIO</a> and ' +
                '<a href="http://www.toca.com" target="_blank">TOCA</a> gefördert zu werden.' +
                '<div class="sponsors">' +
                '<a href="https://www.bmbf.de/" target="_blank"><img src="/sponsors/BMBF-logo-white.svg"/></a>' +
                '<a href="https://okfn.de/" target="_blank"><img src="/sponsors/OKFD-logo-white.svg"/></a>' +
                '<a href="https://prototypefund.de" target="_blank" class="sponsor-prototype-fund"><img src="/sponsors/prototype-fund-logo-white.svg"/></a>' +
                '<a href="https://medken.com" target="_blank" class="sponsor-medken"><img src="/sponsors/medken-white.svg"/></a>' +
                '<a href="https://acanio.com" target="_blank" class="sponsor-acanio"><img src="/sponsors/acanio-logo-white.svg"/></a>' +
                '<a href="https://toca.com/" target="_blank" class="sponsor-toca"><img src="/sponsors/toca-logo-white.svg"/></a>' +
                '</div>',
                AboutPara4: '<div class="legal-notice"><h3>Impressum</h3>' +
                '<div style="margin-bottom: 15px;">Kailona GbR (Inhaber Armin Möhrle, Aysel Afsar, Evren Ozkan, Sanya Glisic)</div>' +
                '<div>Schulstr. 12</div>' +
                '<div>78462 Konstanz</div>' +
                '<div>legal@kailona.org</div>' +
                '<div style="margin-top: 15px;">Alle Produktnamen und Marken sind Eigentum ihrer jeweiligen Inhaber, die möglicherweise nicht mit ' +
                'der Kailona GbR verbunden oder verbunden sind. </div>' +
                '<div style="margin-top: 15px;">Zuletzt bearbeitet am 23.04.2021</div>' +
                '</div>',
                Press: 'Press',
                PressTitle: 'Press',
                PressPara1: '<div class="press-release">' +
                '<div class="press-release-item">' +
                '<a href="https://www.heise.de/news/Kailona-will-Cloud-Alternative-zur-elektronischen-Patientenakte-anbieten-6024875.html" ' +
                'target="_blank" class="press-release-item-title">Kailona will Cloud-Alternative zur elektronischen Patientenakte anbieten</a>' +
                '<div class="press-release-item-source">heise</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://www.golem.de/news/kailona-regierung-unterstuetzt-gesundheitsdaten-in-nextcloud-2104-155904.html" ' +
                'target="_blank" class="press-release-item-title">Regierung unterstützt Gesundheitsdaten in Nextcloud</a>' +
                '<div class="press-release-item-source">golem</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://www.linux-community.de/nachrichten/nextcloud-mit-kailona-app-bietet-open-source-cloud-fuer-gesundheitsdaten/" ' +
                'target="_blank" class="press-release-item-title">Nextcloud mit Kailona-App bietet Open-Source-Cloud für Gesundheitsdaten</a>' +
                '<div class="press-release-item-source">LINUX COMMUNITY</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://osb-alliance.de/news/bundesregierung-finanziert-open-source-cloud-fuer-patientendaten" ' +
                'target="_blank" class="press-release-item-title">Bundesregierung finanziert Open-Source-Cloud für Patientendaten</a>' +
                '<div class="press-release-item-source">OSB ALLIANCE</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://www.linux-magazin.de/news/nextcloud-mit-open-source-app-fuer-patientendaten/"  ' +
                'target="_blank" class="press-release-item-title">Nextcloud mit Open-Source-App für Patientendaten</a>' +
                '<div class="press-release-item-source">LINUX MAGAZIN</div>' +
                '</div>' +
                '<div class="press-release-item">' +
                '<a href="https://c2wlabnews.nl/nextcloud-met-een-open-source-patientgegevensapplicatie/" ' +
                'target="_blank" class="press-release-item-title">Nextcloud met een open source patiëntgegevensapplicatie</a>' +
                '<div class="press-release-item-source">C2W LabNews</div>' +
                '</div>' +
                '</div>',
                GetStarted: 'Loslegen',
                AppStore: 'EHR im Nextcloud App Store'
            }
        },
    }
});

export default i18n;

