import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import LanguageSelect from '../header-components/LanguageSelect';
import './Nav.css'

import pages from '../page-components/Pages';

class Nav extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
      const { t } = this.props;

      return (
      <nav className='visible nav-container'>
          <a href="https://docs.kailona.org/" target="_blank" className="ehr-action">
              {t('GetStarted')}
          </a>
        {pages.map(item => {
            return (item.sublinks) ?
            (
                <div className="dropdown">
                    <div className={window.location.pathname == item.link ? 'nav-item selected' : 'nav-item'}>
                        <Link to={item.link}>{item.name}</Link>
                    </div>
                    <div className="dropdown-content">
                    {item.sublinks.map(sublink => (
                        <div className="nav-item">
                            <Link to={sublink.link}>
                                {sublink.name}
                            </Link>
                        </div>
                    ))}
                    </div>
                </div>

            ) :
            (
                <div className={window.location.pathname == item.link ? 'nav-item selected' : 'nav-item'}>
                    <Link to={item.link}>{t(item.name)}</Link>
                </div>
            )
        })}
        <div className="nav-item">
          <LanguageSelect/>
        </div>
      </nav>
    )
  }
}

export default withTranslation()(withRouter(Nav));
