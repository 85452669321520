import React from 'react';
import Page from './Page';
import pages from '../../pages';

const pageList = [{
    name: 'Dignity',
    link: '/dignity',
    content: [{
        background: <Page page={pages[0]}/>,
    }]
}, {
    name: 'Trust',
    link: '/trust',
    content: [{
        background: <Page page={pages[1]}/>,
    }]
}, {
    name: 'Self-Determination',
    link: '/selfDetermination',
    content: [{
        background: <Page page={pages[2]}/>,
    }]
}, {
    name: 'Healing',
    link: '/healing',
    content: [{
        background: <Page page={pages[3]}/>,
    }]
}, {
    name: 'Press',
    link: '/press',
    content: [{
        background: <Page page={pages[4]}/>,
    }]
}, {
    name: 'About',
    link: '/about',
    content: [{
        background: <Page page={pages[5]}/>,
    }]
}];


export default pageList;
