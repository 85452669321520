import React from "react";
import { Link, withRouter } from 'react-router-dom'
import './Header.css';
import Nav from "./Nav";
import DropdownNav from './DropdownNav'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
    this.updateWindowSize = this.updateWindowSize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize.bind(this))
  }

  updateWindowSize() {
    var windowWidth = window.innerWidth
    this.setState({ windowWidth })
  }

  render() {
    return (
      <div style={{ height: '75px', padding: '0px', backgroundColor: '#fff', width: '100%', display: 'flex', alignItems: 'center'}}>
        <div className="header container" style={{ flex: '1', paddingLeft: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          <Link to="/">
              <img src={process.env.PUBLIC_URL + '/kailona-logo-indigo.svg'} height="55px" alt="Kailona" />
          </Link>
        </div>
        { this.state.windowWidth > 800 ?
          <Nav state={this.props.state} theme={this.props.theme} showHeader={this.props.showHeader} />
          :
          <DropdownNav state={this.props.state} theme={this.props.theme} />
        }
      </div>
    )
  }
}

export default withRouter(Header);
