const pages = [{
    name: 'Dignity',
    link: '/dignity',
    title: 'DignityTitle',
    paragraphs: {
        en: [
            'DignityPara1',
            'DignityPara2',
            'DignityPara3'
        ],
        de: [
            'DignityPara1',
            'DignityPara2',
            'DignityPara3'
        ],
    },
    backgroundName: '*Chylocladia ovalis',
}, {
    name: 'Trust',
    link: '/trust',
    title: 'TrustTitle',
    paragraphs: {
        en: [
            'TrustPara1',
            'TrustPara2',
            'TrustPara3'
        ],
        de: [
            'TrustPara1',
            'TrustPara2',
            'TrustPara3'
        ]
    },
    backgroundName: '*Cystoseira fibrosa',
}, {
    name: 'Self-Determination',
    link: '/selfDetermination',
    title: 'Self-DeterminationTitle',
    paragraphs: {
        en: [
            'Self-DeterminationPara1',
            'Self-DeterminationPara2',
            'Self-DeterminationPara3'
        ],
        de: [
            'Self-DeterminationPara1',
            'Self-DeterminationPara2',
            'Self-DeterminationPara3'
        ]
    },
    backgroundName: '*Ptilota sericea',
}, {
    name: 'Healing',
    link: '/healing',
    title: 'HealingTitle',
    paragraphs: {
        en: [
            'HealingPara1',
            'HealingPara2',
            'HealingPara3',
            'HealingPara4'
        ],
        de: [
            'HealingPara1',
            'HealingPara2',
            'HealingPara3',
            'HealingPara4'
        ]
    },
    backgroundName: '*Chordaria flagelliformis',
}, {
    name: 'Press',
    link: '/press',
    title: 'PressTitle',
    paragraphs: {
        en: [
            'PressPara1',
        ],
        de: [
            'PressPara1',
        ]
    },
    backgroundName: '*Himanthalia lorea'
}, {
    name: 'About',
    link: '/about',
    title: 'AboutTitle',
    paragraphs: {
        en: [
            'AboutPara1',
            'AboutPara2',
            'AboutPara3',
            'AboutPara4'
        ],
        de: [
            'AboutPara1',
            'AboutPara2',
            'AboutPara3',
            'AboutPara4',
        ]
    },
    backgroundName: '*Himanthalia lorea'
}];

export default pages;
