import React from 'react'
import Slider from "react-slick";

const settings = {
  autoPlay: false,
  fade: true,
  dots: true,
  infinite: false,
  draggle: false,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1
};

class Post extends React.Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this)
    this.getIndexes = this.getIndexes.bind(this)
    this.getContent = this.getContent.bind(this)
  }

  state = {
    body: null,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    previousIndex: 0,
    nextIndex: 0,
    resizing: false,
    indexes: [0],
    calculation: 0,
    background: null
  }

  PaginationCalculation = function () {
    /** Sizing Calculations for Pagination functionality */
    let MobileCalculationLarge = (window.innerWidth * window.innerHeight) / 240;
    let MobileCalculationSmall = (window.innerWidth * window.innerHeight) / 300;
    let DesktopCalculationSmall = (window.innerWidth * window.innerHeight) / (this.props.type == 'posts' ? 420 : 340);
    let DesktopCalculationLarge = (window.innerWidth * window.innerHeight) / (this.props.type == 'posts' ? 400 : 340);
    let calculation = ((window.innerWidth > 800) ? (window.innerHeight < 800 ? DesktopCalculationSmall : DesktopCalculationLarge) : window.innerHeight > 700 ? MobileCalculationLarge : MobileCalculationSmall);
    this.setState({ calculation })
  }

  updateDimensions = function () {
    var self = this;
    if (!this.state.resizing) {
      let resizing = true;
      this.setState({ resizing })

      setTimeout(function () {
        let windowWidth = window.innerWidth, windowHeight = window.innerHeight, previousIndex = 0, nextIndex = 0, resizing = false
        let indexes = self.getIndexes()
        self.setState({ windowWidth, windowHeight, previousIndex, nextIndex, resizing, indexes })
      }, 250)
    }
  }

  getIndexes = function () {
    this.PaginationCalculation()
    if (!this.state.body) {
      return [0];
    }
    var body = this.state.body.replace('<p></p>', '').split('</p>'), count = 0, indexes = [0], i = 0;
    while (i < body.length) {
      if (body[i] && (count += body[i].length) < this.state.calculation) {
        count += body[i].length;
      } else {
        indexes.push(i);
        count = 0;
        // Ensure single paragraph is not longer than calculated max-width, otherwise there will be an infinite loop
        if (body[i].length < this.state.calculation) {
          i--;
        }
      }
      i++;
    }

    // Remove empty index from end if applicable
    if (body[indexes[indexes.length - 1]].length == 1) {
      indexes.pop()
    }

    this.setState({ indexes })
    return indexes;
  }

  getContent = function (index) {
    var body = this.state.body.replace('<p></p>', '').split('</p>'), count = 0, i = index, content = "";
    while (i <= body.length) {
      if (body[i] && (count += body[i].length) < this.state.calculation) {
        count += body[i].length;
        content += body[i]
      }
      i++;
    }
    return content;
  }

  paginateTo = function (index) {
    this.state.previousIndex = this.state.indexes[index - 1] == undefined ? 0 : this.state.indexes[index - 1]
    this.state.nextIndex = this.state.indexes[index - 1] == undefined ? 0 : this.state.indexes[index - 1]
  }

  paginateContent = function (input) {
    var body = this.state.body.replace('<p></p>', '').split('</p>'), i = 0, contentBody = '';
    while (i < body.length) {
      if (i >= this.state.previousIndex && i <= this.state.nextIndex) {
        contentBody += body[i]
      }
      i++;
    }
    return contentBody;
  }


  render() {
      return (
          <div className={this.props.theme == "dark " ? 'page post' : 'page light'}>
              <Slider {...settings}>
                  {this.props.content.map(c => c.background)}
              </Slider>

          </div>
    )
  }
}

export default Post;
