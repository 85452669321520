import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DropdownButton, Dropdown } from 'react-bootstrap';

const LANGS = [{
    value: 'en',
    label: 'EN'
}, {
    value: 'de',
    label: 'DE'
}];

class LanguageSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: this.props.i18n.language || 'en'
        }
    }

    componentWillMount() {
        const kailonaLanguage = window.localStorage.getItem('kailonaLanguage');
        if (kailonaLanguage) {
            this.setState({
                lang: kailonaLanguage
            });

            return;
        }

        window.localStorage.setItem('kailonaLanguage', this.props.i18n.language);
    }

    languageChange = (lang) => {
        this.setState({
            lang
        });
        window.localStorage.setItem('kailonaLanguage', lang);

        this.props.i18n.changeLanguage(lang);
    };

    render() {
        return (
            <DropdownButton id="language-select" title={this.state.lang.toUpperCase()}>
                {LANGS.map(lang => (
                    <Dropdown.Item
                        value={lang.value}
                        className={this.state.lang === lang.value ? 'active': ''}
                        onClick={() => this.languageChange(lang.value)}
                    >
                        {lang.label}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    }
}

export default withTranslation()(LanguageSelect)
