import React from 'react'
import Page from '../../page-components/Pages/Page';
import pages from '../../pages';

// Any slider background we will show in the slider should be imported and added here
const sliderBackgrounds = [];

pages.filter(page => page.name !== 'About' && page.name !== 'Press').forEach(page => {
    sliderBackgrounds.push({
        background: <Page page={page} inMain={true}/>
    });
});

export default sliderBackgrounds;
