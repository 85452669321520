import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Main.css'
import Homepage from './home-components/Homepage'
import Post from './page-components/Post'
import Header from './header-components/Header'
import pages from './page-components/Pages';
import { withTranslation } from 'react-i18next';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    siteTitle: null,
    content: null,
    timeout: null,
    delay: null,
    showHeader: true,
    swiping: false,
    redirect: false,
  }

  windowDimension = function () {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  updateNav = function () {
    var self = this;
    let showHeader = true;

    this.clearNav()
    this.setState({ showHeader })

    this.state.timeout = setTimeout(function () {
      showHeader = showHeader = false;
      self.setState({ showHeader })
    }, 1200)
  }

  clearNav = function () {
    if (this.state.timeout != null) {
      clearTimeout(this.state.timeout);
    }
  }

  PageNext = function (event) {
    var self = this
    let location = window.location.pathname

    if (this.state.swiping == false) {
      let swiping = true
      this.setState({ swiping })

      var i = 0;
      while (i < state.theme.menu.length) {
        if (state.theme.menu[i][1] === location) {
          var index = i;
        }
        i++;
      }

      if (index >= 0 && index < state.theme.menu.length - 1 && event.nativeEvent.wheelDelta < 0) {
        let redirect = state.theme.menu[index + 1][1]
        this.setState({ redirect })
      } else if (index >= 0 && index != 0 && event.nativeEvent.wheelDelta > 0) {
        let redirect = state.theme.menu[index - 1][1]
        this.setState({ redirect })
      }

      setTimeout(function () {
        swiping = false
        self.setState({ swiping })
      }, 2000)
    }
  }

  componentDidMount() {
    this.fetchTitle()
  }

  fetchTitle() {
      this.setState({ siteTitle: 'Kailona' })
  }

  render() {
    const { t } = this.props;
    return (
      <Router>
        <div className="App" onMouseMove={(evt) => this.updateNav()} onWheel={(e) => this.PageNext(e)}>
          <header className="App-header">
            <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <link href="https://fonts.googleapis.com/css?family=Nunito+Sans" rel="stylesheet"></link>
          </header>


            <Route exact={true} path="/" render={() => (
                <div className="wrapper" style={{ width: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
                    <Header state={state} title={this.state.siteTitle} showHeader={this.state.showHeader}/>
                    <Homepage state={this.state} />
                </div>
            )}/>

            {
                pages.map(item => {
                  return (
                      <>
                          <Route exact={true} path={item.link} render={({ match }) => (
                              <>
                                  <Header state={state} title={this.state.siteTitle} showHeader={this.state.showHeader}/>
                                  <Post subtitle="Post" content={item.content}/>
                              </>
                          )}
                          />
                      </>
                  )
              })
            }

            <div className="go-to-appstore-content" style={{ position: 'absolute', bottom: '20px', right: '20px'}}>
                <a href="https://apps.nextcloud.com/apps/ehr" target="_blank" className="ehr-action go-to-appstore">
                    {t('AppStore')} &rarr;
                </a>
            </div>
        </div>
      </Router>
    );
  }
}

export default withTranslation()(App);

const state = {
  theme: {
    "content": '',
    "showMenu": false,
    "menu": [],
    "studies": [],
  },
  "router": {
    "link": window.href
  }
}
